<div
    class="faces"
    [class.medium]="medium"
    [class.small]="small"
>
    <app-face
        class="face"
        [handPositions]="handPositions[0]"
        [position]="(position * FACES_PER_DIGIT) + 0"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[1]"
        [position]="(position * FACES_PER_DIGIT) + 1"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[2]"
        [position]="(position * FACES_PER_DIGIT) + 2"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
</div>
<div
    class="faces"
    [class.medium]="medium"
    [class.small]="small"
>
    <app-face
        class="face"
        [handPositions]="handPositions[3]"
        [position]="(position * FACES_PER_DIGIT) + 3"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[4]"
        [position]="(position * FACES_PER_DIGIT) + 4"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[5]"
        [position]="(position * FACES_PER_DIGIT) + 5"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
</div>
<div
    class="faces"
    [class.medium]="medium"
    [class.small]="small"
>
    <app-face
        class="face"
        [handPositions]="handPositions[6]"
        [position]="(position * FACES_PER_DIGIT) + 6"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[7]"
        [position]="(position * FACES_PER_DIGIT) + 7"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[8]"
        [position]="(position * FACES_PER_DIGIT) + 8"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
</div>
<div
    class="faces"
    [class.medium]="medium"
    [class.small]="small"
>
    <app-face
        class="face"
        [handPositions]="handPositions[9]"
        [position]="(position * FACES_PER_DIGIT) + 9"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[10]"
        [position]="(position * FACES_PER_DIGIT) + 10"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[11]"
        [position]="(position * FACES_PER_DIGIT) + 11"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
</div>
<div
    class="faces"
    [class.medium]="medium"
    [class.small]="small"
>
    <app-face
        class="face"
        [handPositions]="handPositions[12]"
        [position]="(position * FACES_PER_DIGIT) + 12"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[13]"
        [position]="(position * FACES_PER_DIGIT) + 13"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[14]"
        [position]="(position * FACES_PER_DIGIT) + 14"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
</div>
<div
    class="faces"
    [class.medium]="medium"
    [class.small]="small"
>
    <app-face
        class="face"
        [handPositions]="handPositions[15]"
        [position]="(position * FACES_PER_DIGIT) + 15"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[16]"
        [position]="(position * FACES_PER_DIGIT) + 16"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
    <app-face
        class="face"
        [handPositions]="handPositions[17]"
        [position]="(position * FACES_PER_DIGIT) + 17"
        [totalFaces]="totalFaces"
        [medium]="medium"
        [small]="small"
        [thin]="thin"
        [rotation]="rotation"
        [animationType]="animationType"
    ></app-face>
</div>
