<div class="digit-wrapper">
    <div class="digits">
        <ng-container *ngFor="let timeDigit of timeDigits; let i = index; let last = last">
            <app-digit
                class="digit"
                [number]="timeCharacters[i]"
                [position]="i"
                [totalFaces]="TIME_TOTAL_FACES"
            ></app-digit>
        </ng-container>
    </div>

    <div class="digits">
        <ng-container *ngFor="let dayDigit of dayDigits; let i = index; let last = last">
            <app-digit
                class="digit"
                [number]="dayCharacters[i]"
                [position]="i"
                [totalFaces]="DAY_TOTAL_FACES"
                [medium]="true"
            ></app-digit>
        </ng-container>
    </div>

    <div class="digits">
        <ng-container *ngFor="let dateDigit of dateDigits; let i = index; let last = last">
            <app-digit
                class="digit"
                [number]="dateCharacters[i]"
                [position]="i"
                [totalFaces]="DATE_TOTAL_FACES"
                [small]="true"
            ></app-digit>
        </ng-container>
    </div>
</div>
