import { Component, HostBinding, Input } from '@angular/core';
import { AnimationType } from '../../models/animation-types.model';
import { HandPosition } from '../../models/hand-position.model';
import { ANIMATION_INTERVAL_SECONDS } from '../constants/animation.constants';
import { FACES_PER_DIGIT } from '../constants/face.constants';
import { DIGIT_HAND_POSITIONS } from '../constants/hand-positions.constants';

@Component({
  selector: 'app-digit',
  templateUrl: './digit.component.html',
  styleUrls: ['./digit.component.scss']
})
export class DigitComponent {

  @Input() set number(number: number) {
    this.handPositions = DIGIT_HAND_POSITIONS[number] || DIGIT_HAND_POSITIONS['unused'];
  }

  @Input() position: number = 0;
  @Input() totalFaces: number = 0;

  @HostBinding('class.medium') @Input() medium = false;
  @HostBinding('class.small') @Input() small = false;
  @HostBinding('class.thin') @Input() thin = false;

  handPositions: HandPosition[][] = [];

  readonly FACES_PER_DIGIT = FACES_PER_DIGIT;

  readonly ANIMATION_TYPES = Object.values(AnimationType) as AnimationType[];

  rotation = 0;
  animationTypeIndex = 0;
  animationType: AnimationType;

  constructor() {
    setInterval(() => {

      this.animationTypeIndex++;
      if (this.animationTypeIndex === this.ANIMATION_TYPES.length) {
        this.animationTypeIndex = 0;
      }

      this.animationType = this.ANIMATION_TYPES[this.animationTypeIndex];

      this.rotation += 360
    }, ANIMATION_INTERVAL_SECONDS * 1000);
  }
}
