import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DigitComponent } from './digit/digit.component';
import { FaceComponent } from './face/face.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([]),
  ],
  declarations: [
    AppComponent,
    DigitComponent,
    FaceComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
