import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { Message } from '../models/message.model';
import { MESSAGE_INTERVAL_SECONDS, MESSAGE_SPEED_SECONDS } from './constants/animation.constants';
import { DATE_DIGITS_COUNT, DATE_TOTAL_FACES, DAY_DIGITS_COUNT, DAY_TOTAL_FACES, TIME_DIGITS_COUNT, TIME_TOTAL_FACES } from './constants/face.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  messageLength = 11

  timeDigits = Array.from({ length: TIME_DIGITS_COUNT }).fill('d');
  dayDigits = Array.from({ length: DAY_DIGITS_COUNT }).fill('d');
  dateDigits = Array.from({ length: DATE_DIGITS_COUNT }).fill('d');

  timeCharacters: string[] = [];
  dayCharacters: string[] = [];
  dateCharacters: string[] = [];

  messages: Message[] = [];
  currentMessageIndex = -1;
  currentMessageWords: string[] = [];
  currentWordIndex = -1

  currentMessageDays = '';
  currentMessageCurrentWord = '';

  inMessageMode = false;

  readonly TIME_TOTAL_FACES = TIME_TOTAL_FACES;
  readonly DAY_TOTAL_FACES = DAY_TOTAL_FACES;
  readonly DATE_TOTAL_FACES = DATE_TOTAL_FACES;

  messagesEnabled = false;

  constructor(private http: HttpClient) {

    setInterval(() => {

      const timeString = moment(new Date()).format('HH[:]mm[:]ss');

      this.timeCharacters =
        this.padArrayToLength(
          timeString
            .split('')
            .map((value) => value.toLowerCase())
          , TIME_DIGITS_COUNT)

      const dayString = this.inMessageMode ? this.currentMessageDays : (moment(new Date()).format('dddd'));

      this.dayCharacters =
        this.padArrayToLength(
          dayString
            .split('')
            .map((value) => value.toLowerCase())
          , DAY_DIGITS_COUNT)

      const dateString = this.inMessageMode ? this.currentMessageCurrentWord : (moment(new Date()).format('Do MMMM'));

      this.dateCharacters =
        this.padArrayToLength(
          dateString
            .split('')
            .map((value) => value.toLowerCase())
          , DATE_DIGITS_COUNT)
    }, 1000)

    if (this.messagesEnabled) {
      this.loadMessages();
    }
  }

  padArrayToLength(array: string[], length: number): string[] {
    const paddingRequired = Math.floor((length - array.length) / 2);
    const padding = Array.from({ length: paddingRequired }).fill('').map((value) => String(value));

    return [...padding, ...array];
  }

  loadMessages() {
    this.http.get<Message[]>(`/assets/messages.json?v=${new Date().getTime()}`)
      .pipe(take(1))
      .subscribe((messages) => {
        this.messages = messages;
        this.currentMessageIndex = -1;
        this.nextMessage();
      })
  }

  nextMessage() {
    this.currentMessageIndex++;
    if (this.currentMessageIndex === this.messages.length) {
      this.currentMessageIndex = -1;
      this.loadMessages();
      return;
    }

    const currentMessage = this.messages[this.currentMessageIndex];

    const days = Math.ceil(moment(currentMessage.date).diff(moment(new Date()), 'days', true));
    const ordinal = (days === 1) ? 'day' : 'days';
    this.currentWordIndex = -1;

    this.currentMessageDays = `${days} ${ordinal}...`;

    this.currentMessageWords = [
      '',
      'until',
      ...currentMessage.until.split(' ')
    ]

    this.inMessageMode = true;

    this.nextWord();
  }

  nextWord() {
    this.currentWordIndex++;

    if (this.currentWordIndex === this.currentMessageWords.length) {
      this.currentWordIndex = -1;
      this.inMessageMode = false;

      setTimeout(() => this.nextMessage(), MESSAGE_INTERVAL_SECONDS * 1000);

      return;
    }

    this.currentMessageCurrentWord = this.currentMessageWords[this.currentWordIndex];

    setTimeout(() => {
      this.nextWord();
    }, MESSAGE_SPEED_SECONDS * 1000);
  }

}
