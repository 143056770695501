import { HandPosition } from "../../models/hand-position.model";

export const HAND_POSITIONS_UNUSED: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
]

export const HAND_POSITIONS_ZERO: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_ONE: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_TWO: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_THREE: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_FOUR: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_FIVE: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_SIX: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_SEVEN: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_EIGHT: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.DownRight],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.DownLeft],

  [HandPosition.UpRight, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.UpLeft, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_NINE: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_A: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_B: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.DownLeft],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.UpLeft, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_C: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_D: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.DownRight, HandPosition.Left],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Down, HandPosition.UpLeft],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.DownLeft],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.UpRight, HandPosition.Left],
  [HandPosition.Off, HandPosition.Off],
]

export const HAND_POSITIONS_E: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_F: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Off, HandPosition.Off],
]

export const HAND_POSITIONS_G: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_H: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_I: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_J: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_K: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.DownLeft],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Down, HandPosition.UpLeft],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_L: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_M: HandPosition[][] = [
  [HandPosition.DownRight, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Down, HandPosition.DownLeft],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.UpRight, HandPosition.UpLeft],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_N: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Right],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_O: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_P: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Off, HandPosition.Off],
]

export const HAND_POSITIONS_Q: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_R: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.DownLeft],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Down, HandPosition.Down],
  [HandPosition.Down, HandPosition.UpLeft],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_S: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_T: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Off, HandPosition.Off],
]

export const HAND_POSITIONS_U: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_V: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.DownRight],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.DownLeft],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.UpRight, HandPosition.UpLeft],
  [HandPosition.Off, HandPosition.Off],
]

export const HAND_POSITIONS_W: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Up],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.DownRight, HandPosition.DownLeft],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.UpRight],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.UpLeft],
]

export const HAND_POSITIONS_X: HandPosition[][] = [
  [HandPosition.DownRight, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Down, HandPosition.DownLeft],

  [HandPosition.Up, HandPosition.DownRight],
  [HandPosition.UpRight, HandPosition.UpLeft],
  [HandPosition.Up, HandPosition.DownLeft],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.UpRight, HandPosition.UpLeft],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.DownRight, HandPosition.DownLeft],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.UpRight, HandPosition.Down],
  [HandPosition.DownRight, HandPosition.DownLeft],
  [HandPosition.Down, HandPosition.UpLeft],

  [HandPosition.Up, HandPosition.UpRight],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.UpLeft],
]

export const HAND_POSITIONS_Y: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_Z: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.DownLeft],
  [HandPosition.Up, HandPosition.DownLeft],

  [HandPosition.UpRight, HandPosition.Down],
  [HandPosition.UpRight, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_EXCLAMATION: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Down],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_FULL_STOP: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
]

export const HAND_POSITIONS_COMMA: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.DownRight],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.UpLeft],
]

export const HAND_POSITIONS_APOSTROPHE: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.DownRight],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.UpLeft],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
]

export const HAND_POSITIONS_HYPHEN: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
]

export const HAND_POSITIONS_QUESTION_MARK: HandPosition[][] = [
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Right, HandPosition.Left],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Down, HandPosition.Left],
  [HandPosition.Up, HandPosition.Down],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.DownLeft],
  [HandPosition.Up, HandPosition.DownLeft],

  [HandPosition.UpRight, HandPosition.Down],
  [HandPosition.UpRight, HandPosition.Down],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],
  [HandPosition.Off, HandPosition.Off],
]

export const HAND_POSITIONS_COLON: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Right, HandPosition.Down],
  [HandPosition.Down, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Up, HandPosition.Right],
  [HandPosition.Up, HandPosition.Left],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
]

export const HAND_POSITIONS_SLASH: HandPosition[][] = [
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Down, HandPosition.DownLeft],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.UpRight, HandPosition.DownLeft],
  [HandPosition.Up, HandPosition.DownLeft],

  [HandPosition.UpRight, HandPosition.Down],
  [HandPosition.UpRight, HandPosition.DownLeft],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Up, HandPosition.UpRight],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],

  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
  [HandPosition.Off, HandPosition.Off],
]

export const DIGIT_HAND_POSITIONS = {
  'unused': HAND_POSITIONS_UNUSED,
  0: HAND_POSITIONS_ZERO,
  1: HAND_POSITIONS_ONE,
  2: HAND_POSITIONS_TWO,
  3: HAND_POSITIONS_THREE,
  4: HAND_POSITIONS_FOUR,
  5: HAND_POSITIONS_FIVE,
  6: HAND_POSITIONS_SIX,
  7: HAND_POSITIONS_SEVEN,
  8: HAND_POSITIONS_EIGHT,
  9: HAND_POSITIONS_NINE,
  a: HAND_POSITIONS_A,
  b: HAND_POSITIONS_B,
  c: HAND_POSITIONS_C,
  d: HAND_POSITIONS_D,
  e: HAND_POSITIONS_E,
  f: HAND_POSITIONS_F,
  g: HAND_POSITIONS_G,
  h: HAND_POSITIONS_H,
  i: HAND_POSITIONS_I,
  j: HAND_POSITIONS_J,
  k: HAND_POSITIONS_K,
  l: HAND_POSITIONS_L,
  m: HAND_POSITIONS_M,
  n: HAND_POSITIONS_N,
  o: HAND_POSITIONS_O,
  p: HAND_POSITIONS_P,
  q: HAND_POSITIONS_Q,
  r: HAND_POSITIONS_R,
  s: HAND_POSITIONS_S,
  t: HAND_POSITIONS_T,
  u: HAND_POSITIONS_U,
  v: HAND_POSITIONS_V,
  w: HAND_POSITIONS_W,
  x: HAND_POSITIONS_X,
  y: HAND_POSITIONS_Y,
  z: HAND_POSITIONS_Z,
  [`!`]: HAND_POSITIONS_EXCLAMATION,
  [`.`]: HAND_POSITIONS_FULL_STOP,
  [`,`]: HAND_POSITIONS_COMMA,
  [`'`]: HAND_POSITIONS_APOSTROPHE,
  [`-`]: HAND_POSITIONS_HYPHEN,
  [`?`]: HAND_POSITIONS_QUESTION_MARK,
  [`:`]: HAND_POSITIONS_COLON,
  [`/`]: HAND_POSITIONS_SLASH,
  [`_`]: HAND_POSITIONS_UNUSED,
}
