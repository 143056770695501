export const FACES_PER_DIGIT = 18;
export const FACES_PER_ROW = 3;
export const ROWS_PER_DIGIT = FACES_PER_DIGIT / FACES_PER_ROW;

export const TIME_DIGITS_COUNT = 8;
export const DAY_DIGITS_COUNT = 10;
export const DATE_DIGITS_COUNT = 14;

export const TIME_TOTAL_FACES = TIME_DIGITS_COUNT * FACES_PER_DIGIT;
export const DAY_TOTAL_FACES = DAY_DIGITS_COUNT * FACES_PER_DIGIT;
export const DATE_TOTAL_FACES = DATE_DIGITS_COUNT * FACES_PER_DIGIT;
