import { Component, ElementRef, HostBinding, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { AnimationType } from '../../models/animation-types.model';
import { HandPosition } from '../../models/hand-position.model';
import { ANIMATION_SPEED_SECONDS } from '../constants/animation.constants';

@Component({
  selector: 'app-face',
  templateUrl: './face.component.html',
  styleUrls: ['./face.component.scss']
})
export class FaceComponent implements OnChanges {

  @Input() handPositions: HandPosition[] = [HandPosition.Up, HandPosition.Right];
  @Input() position: number = 0;
  @Input() rotation: number = 0;
  @Input() totalFaces: number = 0;
  @Input() animationType: AnimationType;

  @HostBinding('class.medium') @Input() medium = false;
  @HostBinding('class.small') @Input() small = false;
  @HostBinding('class.thin') @Input() thin = false;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.position?.currentValue !== undefined) {
      this.renderer.setStyle(this.element.nativeElement, 'transition-duration', `${ANIMATION_SPEED_SECONDS}s`);
    }

    if (changes.rotation?.currentValue !== undefined) {
      let transitionDelay = 0;
      switch (this.animationType) {
        case AnimationType.LeftToRight:
          transitionDelay = (ANIMATION_SPEED_SECONDS / this.totalFaces) * this.position;
          break;

        case AnimationType.RightToLeft:
          transitionDelay = (ANIMATION_SPEED_SECONDS / this.totalFaces) * (this.totalFaces - this.position);
          break;

        case AnimationType.CentreOut:
          transitionDelay = (ANIMATION_SPEED_SECONDS / this.totalFaces) * Math.abs((this.totalFaces / 2) - this.position);
          break;

        case AnimationType.Random:
          transitionDelay = Math.random() * ANIMATION_SPEED_SECONDS;
          break;

        default:
          break;
      }

      this.renderer.setStyle(this.element.nativeElement, 'transition-delay', `${transitionDelay}s`);

      this.renderer.setStyle(this.element.nativeElement, 'transform', `rotate(${this.rotation}deg)`);
    }
  }

  handPositionToDegrees(handPosition: HandPosition): number {
    switch (handPosition) {
      case HandPosition.Off:
        return 225;
      case HandPosition.Up:
        return 0;
      case HandPosition.UpRight:
        return 45;
      case HandPosition.Right:
        return 90;
      case HandPosition.DownRight:
        return 135;
      case HandPosition.Down:
        return 180;
      case HandPosition.DownLeft:
        return 225;
      case HandPosition.Left:
        return 270;
      case HandPosition.UpLeft:
        return 315;

      default:
        break;
    }
  }

  handPositionToOpacity(handPosition: HandPosition): number {
    return handPosition === HandPosition.Off ? 0.1 : 1;
  }
}
