<div
    class="hand hand-1"
    [style.transform]="'rotate(' + handPositionToDegrees(handPositions[0]) + 'deg)'"
    [style.opacity]="handPositionToOpacity(handPositions[0])"
    [style.transition-duration]="'0.5s'"
></div>
<div
    class="hand hand-2"
    [style.transform]="'rotate(' + handPositionToDegrees(handPositions[1]) + 'deg)'"
    [style.opacity]="handPositionToOpacity(handPositions[1])"
    [style.transition-duration]="'0.5s'"
></div>
